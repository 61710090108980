.the-nav-title {
  color: white;

  &:hover {
    color: $theme-colour;
  }
}

.the-nav-dropdown {
  color: black;

  &:hover {
    color: white;
    background-color: $theme-colour;
  }
}

.phonenumber {
  color: white;
  padding: 20px;
  background: $theme-colour;

  &:hover {
    color: white;
  }
}

.btn {
  border-color: #ffffff;
}

.success:hover {
  background-color: #7fabda;
}

.email-link {
  color: black;

  &:hover {
    color: $theme-colour;
  }
}

.email-link-inverted {
  color: white;

  &:hover {
    color: $theme-colour;
  }
}

.indent-fix {
  padding: 30px;
  margin: 10px;
  color: #636363;

  li {
    font-size: 14px;
  }
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: lighten($theme-colour, 15%);
}

.nav-pills .nav-link.active {
  color: #fff;
  background-color: lighten($theme-colour, 15%);
}

.spad {
  padding-top: 50px;
  padding-bottom: 50px;
}

.banner-card {
  opacity: 0.8;
}

@media only screen and (max-width: 991px) {
  ul.nav {
    display: block;
  }
}

.text-theme-colour {
  color: $theme-colour;
}

.page-top-section h2.inverted {
  color: white;
  background-color: rgba(0, 0, 0, .3);
}


// Hack to set all attributes to nothing as
// don't want to modify the template.scss file to remove styles.
.section-title:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  left: 0;
  bottom: 0;
  margin-left: 0;
  background: none;
}


.parallax-window {
  min-height: 500px;
  background: transparent;
}

.relative-container {
  position: relative;
}

.index__splash-box {
  position: absolute;
  left: 0;
  top: 240px;
  width: 100%;
}

.index__splash-text {
  margin: 0 auto;
  width: 90%;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  // color: #0b2e13;

  // From screen size extra small and upwards.
  @media screen and (min-width: $screen-xs) {
    font-size: 43px;
    // color: red;
  }

  // From screen size small and upwards.
  @media screen and (min-width: $screen-sm) {
    font-size: 60px;
    // color: yellow;
  }

  // From screen size small and upwards.
  @media screen and (min-width: $screen-md) {
    font-size: 70px;
    // color: orange;
  }

  // From screen size small and upwards.
  @media screen and (min-width: $screen-lg) {
    font-size: 72px;
    // color: pink;
  }
}

.index__splash-text--inverted {
  background-color: rgba(0, 0, 0, .3);
}

.about-list li:after {
  width: 10px;
  height: 10px;
}

.about-list-left {
  padding-left: 50px;
  float: left;
}

.about-list-right {
  padding-left: 50px;
  float: right;
}

.section-title {
  margin-bottom: 10px;
}

.total-line {
  border-top: black double 3px;
  border-bottom: black double 3px;
}

.dont-display {
  display: none;
}

.invalid-input {
  border: red solid 2px;
}